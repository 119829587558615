<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-11-09 09:39:49
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-07 17:41:23
 * @FilePath: \yda_web_manage\src\views\businessManage\business\add.vue
 * @Description: 流程名称
-->
<template>
  <div class="add">
    <a-spin :spinning="spinning" style="margin-top: 100px;">
      <div class="add-tab">
        <div class="tab-left">
          <a-button @click="goOut">返回</a-button>
        </div>

        <div class="tab-right">
          <a-button
            v-if="
              $route.query?.isDraft === '1' ||
                !$route.query?.isDraft ||
                $route.query?.isCopy
            "
            @click="saveDraft"
            :loading="spinning"
            >保存草稿</a-button
          >
          <a-button
            @click="validate"
            type="primary"
            :loading="spinning"
            class="tab-right-rel"
            >发布</a-button
          >
        </div>
      </div>
      <!-- tab切换 -->
      <div class="tab-main">
        <a-tabs v-model:activeKey="activeKey" class="tab-item">
          <a-tab-pane key="1" tab="基础信息" class="tab-one">
            <Basic ref="basic" :isDraft="$route.query?.isDraft" />
          </a-tab-pane>
          <!-- TODO:参数设置 -->
          <a-tab-pane :forceRender="true" key="2" tab="参数设置">
            <ParameterSetting ref="parameterSetting" :isBind="isBind" />
          </a-tab-pane>
          <a-tab-pane :forceRender="true" key="3" tab="表单设计">
            <FormDesign ref="formDesign" />
          </a-tab-pane>
          <a-tab-pane :forceRender="true" key="4" tab="流程设计">
            <flowPath ref="proDesign" class="tabPane" />
          </a-tab-pane>
          <!-- 居中显示 -->
          <template #renderTabBar="{ DefaultTabBar, ...props }">
            <component
              :is="DefaultTabBar"
              v-bind="props"
              :style="{ background: '#fff', textAlign: 'center' }"
            />
          </template>
        </a-tabs>
      </div>
      <Footer v-if="activeKey === '1'" class="foot"></Footer>
    </a-spin>
    <!-- 校验弹窗 -->
    <a-modal
      :title="null"
      :footer="null"
      v-model:visible="modalVisible"
      centered
    >
      <div class="modal">
        <div class="modal-header">
          <ExclamationOutlined class="icon" />
          <span>当前无法发布</span>
          <span class="tip">(以下内容不完善，请修改后发布)</span>
        </div>
        <div class="modal-main">
          <template v-for="item in errList" :key="item.title">
            <div class="main-item" v-if="item.err.length > 0">
              <div class="item-header">{{ item.title }}</div>
              <p class="item-content" v-for="(el, index) in item.err" :key="el">
                {{ index + 1 }}、{{ el }}
              </p>
            </div>
          </template>
        </div>
        <div class="footer">
          <a-button type="primary" @click="goEdit">去修改</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref, createVNode, computed, onMounted } from 'vue'
import Basic from './components/basic.vue'
import ParameterSetting from './components/ParameterSetting.vue'
import Footer from '@/components/Footer/index'
import flowPath from './components/flowPath.vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import FormDesign from '@/views/formDesign/index'
import { useStore } from 'vuex'
import { stringifyStyle } from '@vue/shared'
import { getDraft, addProcess, editProcess, queryProcess } from '@/apis/process'
import { getBind, getConfigure } from '@/apis/businessManage'

const basic = ref()
const proDesign = ref()
const formDesign = ref()
// const basicForm = computed(() => store.state.process.basic)
const activeKey = ref('1')
const router = useRouter()
const $route = useRoute()
const store = useStore()
const loading = ref(false)
const modalVisible = ref(false) // 弹窗
const spinning = ref(false) // 加载动作
const errList = ref({
  basic: { title: '基础信息', err: [], value: 'basic', activeKey: '1' },
  form: { title: '表单设计', err: [], value: 'form', activeKey: '3' },
  process: { title: '流程设计', err: [], value: 'process', activeKey: '4' }
})
const isBind = ref(true) // 是否绑定高拍仪
const processId = $route.query.processId
const isCopy = $route.query.isCopy
// 表单信息
const formState = computed(() => store.state.process.design.formItems)
const process = computed(() => store.state.process)
const mainSwitch = ref(true) // 参数设置是否开启用印后抓拍

const getParams = () => {
  const pBasic = process.value.basic
  // console.log('保存草稿', pBasic)
  console.log('流程设计', process.value.design.process)
  formState.value.forEach((item) => {
    if (item.type === 'TAKE_OUT') {
      console.log('外带对象', item)
      console.log('看看是否开启了外带呀', item.props.showTakeOut)
      if (!item.props.showTakeOut) {
        pBasic.takeOut = 2
      } else {
        if (item.props.required) pBasic.takeOut = 1
      }
    }
  })
  if (!process.value.isSetDays) {
    //未勾选了审批天数
    pBasic.printTime = undefined
  }
  if (!process.value.isSetHours) {
    //未勾选了盖印小时
    pBasic.stampingTime = undefined
  }
  process.value.basic = {
    ...pBasic,
    scopeList: pBasic.scopeType === 1 ? [] : pBasic.scopeList,
    coverList: pBasic.coverType === 1 ? [] : pBasic.coverList
  }
  const sealList = process.value.basic.sealList.map((item) => {
    return { sealId: item.sealId }
  })

  //盖印方式视频录制与范围用印 1开启2关闭
  formState.value.map((item) => {
    if (item.type === 'REMOTE') {
      item.props.options.map((item) => {
        if (item.name == '常规盖印') {
          process.value.basic.videoType = item.childSelect.includes(2) ? 1 : 2
          process.value.basic.sealDiscern = item.childSelect.includes(1) ? 1 : 2
        }

        if (item.name == '连续盖印') {
          process.value.basic.continuousVideoType = item.childSelect.includes(2)
            ? 1
            : 2
          process.value.basic.continuousSealDiscern = item.childSelect.includes(
            1
          )
            ? 1
            : 2
        }

        if (item.name == '远程盖印') {
          process.value.basic.remoteVideoType = item.childSelect.includes(2)
            ? 1
            : 2
          process.value.basic.remoteSealDiscern = item.childSelect.includes(1)
            ? 1
            : 2
        }
      })
    }
  })

  console.log(process.value.basic, '流程参数')

  const params = {
    enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result
      .enterpriseId,
    ...process.value.basic,
    sealList,
    formItems: JSON.stringify(formState.value),
    process: JSON.stringify(process.value.design.process)
  }

  return params
}

const saveDraft = async () => {
  spinning.value = true
  const params = getParams()
  if (isCopy) {
    params.processId = null
  }
  getDraft(params).then((res) => {
    console.log(res)
    if (res.success) {
      cmsNotice('success', '保存草稿成功')
      successFun()
    }
    setTimeout(() => {
      spinning.value = false
    }, 500)
  })
}

//范围用印&录制视频 默认开启
const getChildSelect = (name, data) => {
  let select = []
  if (name == '常规盖印') {
    if (data.sealDiscern == 1) select.push(1)
    if (data.videoType == 1) select.push(2)
  }
  if (name == '连续盖印') {
    //兼容老数据 continuousSealDiscern&continuousVideoType 没有的情况下
    if (
      data.continuousSealDiscern == 1 ||
      data.continuousSealDiscern == undefined
    )
      select.push(1)
    if (data.continuousVideoType == 1 || data.continuousVideoType == undefined)
      select.push(2)
  }

  if (name == '远程盖印') {
    //兼容老数据 remoteSealDiscern&remoteVideoType 没有的情况下
    if (data.remoteSealDiscern == 1 || data.remoteSealDiscern == undefined)
      select.push(1)
    if (data.remoteVideoType == 1 || data.remoteVideoType == undefined)
      select.push(2)
  }

  return select
}

onMounted(async () => {
  store.commit('setIsPreview', false)
  if (processId) {
    //回显流程名称await
    const params = {
      processId: processId,
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo'))
        .result.enterpriseId
    }
    const processList = store.state.process
    queryProcess(params).then((res) => {
      console.log(res)
      if (res.success) {
        console.log('获取到的配置', res.data)
        const data = res.data
        processList.design.process = JSON.parse(data.process)
        processList.design.formItems = JSON.parse(data.formItems)
        console.log(
          '999',
          processList.design.formItems,
          processList.design.process
        )
        let isMultipleAddress = false
        processList.design.formItems.forEach((item) => {
          if (
            item.configName === 'SealInfo' &&
            item.props.items.length === 3 &&
            !item.props.items[2].props.stamp
          ) {
            item.props.items[2].props = {
              requirred: true,
              stamp: [
                { title: '申请人', isCheck: false }
                // { title: '保管员', isCheck: true }
              ]
            }
          }
          if (item.configName === 'TakeOut') {
            isMultipleAddress = item.props.isMultipleAddress
          }

          if (item.configName == 'Remote') {
            if (Array.isArray(item.props.options)) {
              item.props.options = item.props.options.map((item) => {
                const obj = typeof item == 'string' ? { name: item } : item
                return {
                  ...obj,
                  isOpen: obj.isOpen != undefined ? obj.isOpen : true,
                  childSelect: getChildSelect(item.name, data)
                }
              })
            }
            console.log(item)
          }
        })
        processList.basic = {
          coverSwitch: data.coverSwitch,
          coverType: data.coverType || 1,
          processType: data.processType,
          custodianList: data.custodianList,
          icon: data.icon,
          name: data.name,
          remark: data.remark,
          scopeList: data.scopeList,
          scopeType: data.scopeType,
          sealList: data.sealList, //TODO:核对接收到的值是否有需要的所属组织字段
          coverList: data.coverList,
          processId: data.processId,
          videoType: data.videoType, // 录视频 现用于常规
          sealDiscern: data.sealDiscern, // 视频识别 现用于常规
          ocrType: data.ocrType, // OCR状态
          ocrPattern: data.ocrPattern, // OCR模式
          ocrRetriesNumber: data.ocrRetriesNumber, // OCR重复对比次数
          ocrStrategy: data.ocrStrategy, //OCR通过策略
          ocrDegree: data.ocrDegree, // OCR匹配率
          sealRange: data.sealRange,
          isMultipleAddress,
          // TODO:参数设置
          beforeSealPhoto:
            data.beforeSealPhoto !== null ? data.beforeSealPhoto : 2, // 用印前拍照
          afterSealSnap: data.afterSealSnap !== null ? data.afterSealSnap : 2, // 用印后自动抓拍
          snapInterval: data.snapInterval ?? '1.5', //自动抓拍时间间隔
          makeUp: data.makeUp, //强制补拍
          authentication: data.authentication, //盖印需要盖印人验证指纹
          usageTime: data.usageTime ?? 2, //用印时间限制
          printTime: data.printTime ?? 3, //用印人在获得审批后几天内完成用印
          stampingTime: data.stampingTime ?? 6, //用印人在发生盖印后几小时内完成用印
          locationLimit: data.locationLimit ?? 2, //用印地点限制
          fastSeal: data.fastSeal ?? 1, //是否开启指纹快捷用印

          remoteAuditor: data?.remoteAuditor ?? [], // 远程确认人
          remoteAuditorVO: data.remoteAuditorVO,
          remoteAll: data?.remoteAll ?? true,
          continuousSealDiscern: data?.continuousSealDiscern ?? 2, //连续用印范围用印
          continuousVideoType: data?.continuousVideoType ?? 2, //连续用印录视频
          qrcodeType: data?.qrcodeType ?? 2, //二维码水印开关
          qrcodePageType: data?.qrcodePageType ?? 1, //单页或单文件
          remoteVideoType: data?.remoteVideoType ?? 2, // 远程录制视频
          remoteSealDiscern: data?.remoteSealDiscern ?? 2, //远程识别
          equipmentRec: data?.equipmentRec ?? 2, //天玺拍摄视频
          takeOut: data.takeOut // 强制外带
        }
        if (isCopy) {
          processList.basic.name = ''
        }
        // TODO:
        processList.isSetDays = data.usageTime === 1 && Boolean(data.printTime)
        processList.isSetHours =
          data.usageTime === 1 && Boolean(data.stampingTime)
      }
    })
  } else {
    //新建流程名称
    const user = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
    store.state.process.basic = {
      coverSwitch: 2,
      coverType: 1,
      custodianList: [
        {
          staffName: user.nickname,
          staffId: user.result.staffId
        }
      ],
      processType: 1,
      videoType: 2,
      ocrDegree: 80,
      ocrPattern: 1,
      ocrRetriesNumber: 3, //OCR重复对比次数
      ocrStrategy: 1,
      sealDiscern: 2,
      icon: '',
      name: '',
      remark: '',
      scopeList: 'all',
      scopeType: 1,
      sealList: [],
      coverList: 'business',
      // TODO:参数设置
      beforeSealPhoto: 2,
      afterSealSnap: 1,
      snapInterval: '1.5',
      makeUp: 2,
      authentication: 1,
      usageTime: 2,
      printTime: 3,
      stampingTime: 6,
      locationLimit: 2,
      fastSeal: 1,
      remoteAuditor: [],
      remoteAll: true,
      continuousSealDiscern: 1,
      continuousVideoType: 1,
      remoteVideoType: 1, // 远程录制视频
      remoteSealDiscern: 1, //远程识别
      qrcodeType: 2,
      qrcodePageType: 1,
      equipmentRec: 2, //天玺拍摄视频
      takeOut: 2
    }
    store.state.process.selectFormItem = null
    // TODO:
    store.state.process.isSetDays = false
    store.state.process.isSetHours = false
  }
  isBind.value = (await getBind()).data
  console.log('isBind', isBind.value)
  console.log('000111', store.state.process.basic)
})

// 返回
const goOut = (value) => {
  Modal.confirm({
    title: '是否返回！',
    width: '400px',
    content: '请确认是否返回',
    centered: true,
    confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      successFun()
    }
  })
}

// 校验基础信息
const basicVal = async () => {
  console.log('1111111process.value.basic', process.value.basic)
  let basicMap = []
  const proBasic = process.value.basic
  await basic.value.validateForm()
  proBasic.name === '' && basicMap.push('未设置流程名称')
  proBasic.icon === '' && basicMap.push('未设置图标')
  proBasic.sealList.length === 0 && basicMap.push('未设置关联印章')
  proBasic.custodianList.length === 0 && basicMap.push('未设置业务流程负责人')
  if (
    (proBasic.scopeType === 2 && proBasic.scopeList.length === 0) ||
    (proBasic.scopeType === 1 && proBasic.scopeList === undefined)
  ) {
    basicMap.push('未设置适用范围')
  }
  if (proBasic.coverList.length === 0 && proBasic.coverType === 2) {
    basicMap.push('未设置补盖审批人')
  }
  if (!proBasic.remoteAll && proBasic.remoteAuditor.length === 0) {
    basicMap.push('未设置远程确认人')
  }
  errList.value['basic'].err = basicMap
  console.log('basicMap', basicMap)
}

// 流程设计
const designVal = () => {
  errList.value['process'].err = []
  const err = proDesign.value.validate()
  console.log('222流程设计的校验', errList)
  errList.value['process'].err = err
}

let firstErr = {} // 第一个报错控件
// 表单设计
const formVal = () => {
  const err = [] // 抛出的信息
  console.log('这是表单', formState.value)
  const titleList = [] // 所有的控件名称
  const configList = [] // 报错的控件名称
  errList.value['form'].err = []
  // 把名称摘出来，有两个相同的就push
  formState.value.forEach((item) => {
    if (item.controlName === 'DateTimeRange') {
      titleList.push(item.props.durationTitle)
      if (item.props.durationTitle === '') {
        err.push('控件名称不可为空')
      }
      configList.push(item.title)
    } else if (
      item.controlName === 'MultipleSelect' ||
      item.controlName === 'SelectInput'
    ) {
      // 单选框"SelectInput"，多选框"MultipleSelect"设置名称不能重复
      const etlength = setRemove(item.props.options)
      // console.log(etlength, item.props.options.length);
      item.props.options.forEach((el, index) => {
        if (el === '') {
          configList.push(item.title)
          err.push(`${item.title}选项设置不可为空`)
        } else if (etlength.length < item.props.options.length) {
          item.title && err.push(`${item.title}选项设置不可重复`)
          configList.push(item.title)
        }
      })
    } else if (
      item.controlName === 'SealInfo' ||
      item.controlName === 'TAKE_OUT'
    ) {
      // 印章控件组
      item.props.items.forEach((el) => {
        titleList.push(el.title)
      })
      if (
        item.controlName === 'SealInfo' &&
        process.value.basic.processType === 1
      ) {
        if (item.props.items[2].props.stamp.length === 0) {
          err.push('请至少选中一个盖印人选项')
        }
      }
    }
    if (item.type == 'REMOTE' && process.value.basic.ocrType == 1) {
      console.log('寄哪里饿了1', item.props.options)
      item.props.options.forEach((i) => {
        if (i.name == '远程盖印' && i.isOpen) {
          err.push('远程盖印不支持ocr文件比对')
          if (process.value.basic.ocrPattern == 2) {
            err.push('连续用印不支持ocr文件对比--单页拍摄盖印。')
          }
          configList.push(item.title)
        }
      })
    }

    console.log('寄哪里饿了', item.type, process.value.basic.ocrType)
    if (item.controlName === 'SealInfo') return
    titleList.push(item.title)
  })
  // 数组元素是否重复
  for (var i = 0; i < titleList.length; i++) {
    if (titleList[i] === '') {
      console.log(titleList[i])
      err.push('控件名称不可为空')
      configList.push(titleList[i])
    } else {
      titleList.forEach((item) => {
        // console.log(item);
        if (
          titleList.indexOf(item) !== titleList.lastIndexOf(item) &&
          configList.indexOf(item) === -1
        ) {
          console.log(item)
          configList.push(item)
          item && err.push(`表单[${item}]名称重复`)
        }
      })
    }
  }
  console.log('configList', configList)
  // 去重
  errList.value['form'].err = setRemove(err)

  // 定位到第一个报错的控件
  firstErr = formState.value.filter((el) => el.title === configList[0])
}

const setRemove = (list) => {
  return Array.from(new Set(list))
}

// 发布
const validate = async () => {
  spinning.value = true
  await basicVal() // 基本信息
  await designVal() // 流程设计
  await formVal()

  const isBindGpy = (await getBind()).data
  // console.log(isBindGpy, isBind.value, process.value.basic.afterSealSnap);
  console.log('传给后端', process.value.basic)

  // 有错误信息
  if (
    errList.value['basic'].err.length !== 0 ||
    errList.value['form'].err.length !== 0 ||
    errList.value['process'].err.length !== 0
  ) {
    if (!isBindGpy && isBind.value && process.value.basic.afterSealSnap === 1) {
      errList.value['basic'].err.push(
        '当前企业下无印控仪，无法用印后抓拍，请关闭抓拍按钮'
      )
    }
    modalVisible.value = true
    spinning.value = false
  } else {
    if (!isBindGpy && isBind.value) {
      cmsNotice('warning', '当前企业下无印控仪，无法用印后抓拍')
    }

    const params = getParams()

    // if (item.type == 'REMOTE' && process.value.basic.ocrType == 1) {
    //   item.props.options.forEach(i => {
    //     if (i.name == '远程盖印' && i.isOpen) {
    //       err.push('远程盖印不支持ocr文件比对')
    //     }
    //   })
    // }

    // TODO :参数设置勾选了用印人在发生盖印后几小时内完成用印选项，但没有填写具体时间，警告拦截
    // 新增/修改
    if (
      process.value.isSetHours &&
      (process.value.basic.stampingTime == '' ||
        process.value.basic.stampingTime == null)
    ) {
      cmsNotice('error', '请输入超时时间')
      setTimeout(() => {
        spinning.value = false
      }, 500)
      return
    }
    const processApi =
      processId && !isCopy
        ? editProcess({ ...params, processId: processId })
        : addProcess(params)
    processApi.then((res) => {
      console.log(res)
      setTimeout(() => {
        spinning.value = false
      }, 500)
      if (res.success) {
        cmsNotice(
          'success',
          `${processId && !isCopy ? '修改成功' : '发布成功'}`
        )
        successFun()
      }
    })
  }
}

const successFun = () => {
  router.back()
  store.state.process.design.formItems = null
  store.state.process.selectFormItem = null
}

const goEdit = () => {
  console.log('修改', errList.value)
  // 表单-报错时定位到具体控件
  store.state.process.selectFormItem = firstErr[0]
  // 循环报错信息，找到第一个
  for (let key in errList.value) {
    if (errList.value[key].err.length > 0) {
      activeKey.value = errList.value[key].activeKey
      break
    }
  }
  modalVisible.value = false
}

onBeforeRouteLeave(() => {
  Modal.destroyAll()
})
</script>

<style scoped lang="scss">
.add {
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 12px;
  .foot {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
  .add-tab {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 40px;
    height: 60px;
    background-color: #fff;
    z-index: 10;
    margin: 0px auto;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
    .ant-tabs-bar {
      border: none;
    }
  }
  :deep(.ant-tabs-tab) {
    color: #999999;
    font-weight: 500;
    font-size: 16px;
    padding: 11px 16px;
  }
  :deep(.ant-tabs-tab-active) {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  :deep(.ant-tabs-ink-bar) {
    width: 40px !important;
    left: 28px;
  }
  :deep(.ant-tabs-nav-container) {
    z-index: 11;
    position: fixed;
    // margin-left: 300px;
    width: calc(100vw - 500px);
    margin: 0 auto;
  }
  .tab-right-rel {
    margin-left: 12px;
  }
  .ant-tabs {
    overflow: visible;
  }
  .tab-main {
    position: relative;
    top: 16px;
    width: calc(100vw - 500px);
    margin: 0px auto;
    .tabPane {
      overflow: auto;
      width: 100vw;
      height: 100vh;
      margin: 0 auto;
      margin-left: -240px;
    }
    .tabPane::-webkit-scrollbar {
      display: flex !important;
    }
    .tab-item {
      .tab-two {
        color: #333333;
        font-weight: 500;
        font-size: 16px;
      }
      .tab-one {
        font-weight: 500;
        color: #999999;
      }
    }
    :deep(.ant-tabs-ink-bar) {
      bottom: 3px;
    }
  }
}
.modal {
  .modal-header,
  .modal-text {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0px;
    .icon {
      background-color: #faad14;
      color: #fff;
      border-radius: 50%;
      padding: 3px 0px;
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }
    .tip {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
      margin-left: 8px;
    }
    .tip-text {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 22px;
      font-size: 14px;
    }
  }
  .modal-text {
    margin: 8px 0;
  }
  .modal-main {
    .main-item {
      background-color: #f5f6f7;
      border-radius: 2px;
      padding: 24px;
      font-size: 14px;
      margin-bottom: 8px;
      .item-header {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 26px;
      }
      .item-content {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 10px;
      }
    }
  }
  .footer {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
